import { EmptyCart, InfoButton } from 'assets/img';
import AddItemModal from 'components/AddItemModal';
import ConfirmationModal from 'components/ConfirmationModal';
import DeliveryPickupTabs from 'components/DeliveryPickupTabs';
import EmbedHTML from 'components/Shared/EmbedHTML';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { StoreContext } from 'pages/_app';
import { useContext, useEffect, useState } from 'react';
import { groupBy, isPresent } from 'utils/helpers/array';
import { longDateFormat } from 'utils/helpers/dateTime';
import { priceFormatter } from 'utils/helpers/validation';
import CartItem from './CartItem';
import Tooltip from 'components/Tooltip';
import { getPreferences } from 'utils/helpers/restaurants';

function Cart({ restaurantDetail, setDeliveryPickupModal, markItemAsSoldOut }) {
  const [removeItemId, setRemoveItemAlert] = useState(null);
  const [clearCartAlert, setClearCartAlert] = useState(null);
  const [editItemModal, setEditItemModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [attachLabelAlert, setAttachLabelAlert] = useState(null);
  const [minimumOrderPref, setMinimumOrderPref] = useState(null);
  const store = useContext(StoreContext);
  const userStore = toJS(store?.userStore);
  const cartStore = toJS(store?.cartStore);
  const restaurantStore = toJS(store?.restaurantStore);
  const router = useRouter();
  let { cart, unCheckedOutOrder } = cartStore;
  let { address, addressTimezone } = userStore;
  let { activeRestaurant } = restaurantStore;

  const onClickRemove = () => {
    if (removeItemId) {
      store?.userStore?.setLoader(true);
      store?.cartStore?.removeCartItem(removeItemId).then(() => {
        store?.userStore?.setLoader(false);
        store?.cartStore?.getCartItems(unCheckedOutOrder?.id);
        setRemoveItemAlert(null);
      });
    }
  };

  const onClearCart = () => {
    store?.userStore?.setLoader(true);
    store?.cartStore?.removeAllCartItems(unCheckedOutOrder?.id).then(() => {
      store?.userStore?.setLoader(false);
      setClearCartAlert(null);
    });
  };

  const editCartItem = cartItem => {
    setEditItem(cartItem);
    setEditItemModal(true);
  };

  const minOrder = (
    <span className="text-primary-black text-md font-inter-regular">
      Minimum order:{' '}
      <span className="font-inter-medium">
        {priceFormatter(restaurantDetail?.attributes?.order_minimum_amount)}
      </span>
    </span>
  );

  const updateItem = (id, payload) => {
    store.userStore.setLoader(true);
    store?.cartStore
      ?.updateCartItem(id, {
        order_item: payload,
      })
      .then(data => {
        store.userStore.setLoader(false);

        store?.cartStore?.getCartItems(unCheckedOutOrder?.id);

        if (data?.response?.data?.sold_out) {
          markItemAsSoldOut(payload?.menu_category_id, payload?.menu_item_id);
        }

        setEditItemModal(false);
      });
  };

  const isAbleToCheckout = () =>
    cart?.data?.attributes?.subtotal >= restaurantDetail?.attributes?.order_minimum_amount &&
    cart?.cartDate &&
    cart?.cartTime;

  const getAppropriateValue = (allPref, subtotal) => {
    let selectedValue = null;

    if (isPresent(allPref))
      for (const pref of allPref) {
        if (subtotal >= pref.minimum_amount) {
          if (!selectedValue || pref.minimum_amount > selectedValue.minimum_amount) {
            selectedValue = pref;
          }
        }
      }

    return selectedValue ?? allPref?.length > 0 ? allPref[0] : [];
  };

  useEffect(() => {
    setMinimumOrderPref(
      getAppropriateValue(
        restaurantDetail?.attributes?.minimum_order_preferences,
        cart?.data?.attributes?.subtotal
      )
    );
  }, [cart?.data?.attributes?.subtotal, restaurantDetail?.attributes?.minimum_order_preferences]);

  return (
    <div className="flex flex-col flex-grow-1 overflow-auto min-h-[calc(100vh-50px)] md:min-h-[calc(100vh)] max-h-screen md:max-h-[calc(100vh)] md:border border-background md:mt-4 rounded-3xl p-6 md:sticky top-[0.5rem]">
      <span className="text-divider text-md">Your cart</span>

      {cart?.items?.length == 0 ? (
        <>
          <div className="flex items-center w-3/4">
            <span className="font-inter-medium text-md2 mr-2 text-black-light">
              {restaurantDetail?.attributes?.display_name}
            </span>
          </div>

          {restaurantDetail && (
            <div className="flex flex-col mt-3 bg-background p-3 rounded-xl">
              {minOrder}

              <div className="flex items-start mt-3">
                <span className="text-primary-black text-md font-inter-regular">
                  Deadline to order:{' '}
                  <span className="font-inter-medium">
                    from {minimumOrderPref?.required_notice_time} hours in advance
                  </span>
                </span>

                <div className="mt-[6px] ml-1">
                  <Tooltip
                    grayIcon
                    tooltipText={getPreferences(restaurantDetail)}
                    id="deadline-info-tooltip"
                    backgroundColor={'white'}
                    textColor="black"
                    place={'bottom'}
                  />
                </div>
              </div>

              <span className="text-primary-black text-md mt-3 font-inter-regular">
                Next available delivery date:{' '}
                <span className="font-inter-medium">
                  {activeRestaurant?.attributes?.next_available_date
                    ? moment(activeRestaurant?.attributes?.next_available_date).format(
                        longDateFormat
                      )
                    : '-'}
                </span>
              </span>
            </div>
          )}

          <div className="flex flex-col justify-center items-center my-10">
            <div className="mt-5">
              <Image
                src={EmptyCart}
                width={200}
                height={150}
                className="bg-white"
                alt="empty-cart"
              />
            </div>

            <span className="font-inter-medium text-md2 text-primary-black mt-3">
              Add your first item to cart
            </span>
          </div>
        </>
      ) : (
        <div className="flex flex-col flex-1 justify-between">
          <div>
            <div className="flex justify-between items-center mt-1">
              <div className="flex items-center w-3/4">
                <span className="font-inter-medium text-md2 mr-2 text-black-light">
                  {cart?.data?.attributes?.menu_display_name_odc}
                </span>
              </div>

              <button
                onClick={() => setClearCartAlert({ clearCart: true })}
                className="text-sm btn-style text-hh-accent-dark">
                Clear Cart
              </button>
            </div>

            <div className="mt-6">
              <DeliveryPickupTabs />

              <div className="flex flex-col mt-4">
                {address && (
                  <span className="text-md font-inter-regular">
                    Address: <span className="font-inter-medium">{address}</span>
                  </span>
                )}

                {cart?.cartDate && (
                  <span className="text-md mt-2 font-inter-regular">
                    Delivery date:{' '}
                    <span className="font-inter-medium">
                      {moment(cart?.cartDate).format(longDateFormat)}
                    </span>
                  </span>
                )}

                {cart?.cartTime && (
                  <span className="text-md mt-2 font-inter-regular">
                    Delivery time:{' '}
                    <span className="font-inter-medium">
                      {`${cart?.cartTime} ${addressTimezone ?? ''}`}
                    </span>
                  </span>
                )}
              </div>

              <button
                onClick={() => setDeliveryPickupModal({ state: true, fromCart: true })}
                className="flex flex-col mt-2 btn-style">
                <span className="text-md text-hh-accent-light hover:text-hh-accent-dark">
                  Change delivery details
                </span>
              </button>
            </div>

            <div className="mt-4 mb-10 md:mb-0">
              {Object.values(groupBy(cart?.items, item => item?.attributes?.menu_category_id))?.map(
                entry => (
                  <>
                    <div className="flex justify-between items-center bg-background p-3 rounded-md">
                      <span className="text-md text-primary-black">
                        <EmbedHTML
                          text={
                            entry[0]?.attributes?.category_display_name_with_html ||
                            entry[0]?.attributes?.category
                          }
                          className="text-primary-black"
                          append={` (${entry?.length})`}
                        />
                      </span>
                    </div>

                    {entry?.map(cartItem => (
                      <CartItem
                        moreThanOneItemInCategory={entry.length > 1}
                        cartItem={cartItem}
                        key={cartItem.id}
                        setEditItem={editCartItem}
                        setRemoveItemAlert={setRemoveItemAlert}
                        setAttachLabelAlert={setAttachLabelAlert}
                        markItemAsSoldOut={markItemAsSoldOut}
                      />
                    ))}
                  </>
                )
              )}
            </div>
          </div>

          <div className="flex flex-col justify-between md:mt-4 bg-white md:bg-transparent pb-16 md:pb-0 md:px-0 overflow-scroll md:static w-full md:w-auto">
            <div className="flex flex-col mt-2 md:mt-10">
              <div className="flex mb-4">
                <div className="mr-3">
                  <Image
                    src={InfoButton}
                    layout="fixed"
                    width={16}
                    height={16}
                    className="bg-white"
                    alt="info-button"
                  />
                </div>

                <span className="text-sm">
                  If you have a promo code or a discount, you will be able to apply it on the
                  payments page prior to payment
                </span>
              </div>

              <div className="bg-background p-2 rounded-md">
                <label className="flex justify-between items-center py-1.5 text-sm select-non cursor-pointer">
                  <span className="ml-1 font-inter-regular text-sm w-40">Request cutlery</span>

                  <input
                    autoComplete="off"
                    type="checkbox"
                    className="form-checkbox"
                    onChange={e => store.cartStore.updateCutlery(e.target.checked)}
                    checked={cart.cutlery}
                  />
                </label>
              </div>

              <button
                onClick={() => {
                  if (router?.query?.utm_source)
                    router.push({
                      pathname: `/checkout/${cart?.data?.attributes?.slug}`,
                      query: { utm_source: true },
                    });
                  else router.push(`/checkout/${cart?.data?.attributes?.slug}`);
                }}
                className="btn-purple mt-5 py-3 h-12"
                disabled={!isAbleToCheckout()}>
                {/* text-secondary-gray if disabled */}
                {!isAbleToCheckout() ? (
                  <span className="text-sm font-inter-medium text-secondary-text">
                    {priceFormatter(cart?.data?.attributes?.subtotal)} • Minimum order total:{' '}
                    {priceFormatter(restaurantDetail?.attributes?.order_minimum_amount)}
                  </span>
                ) : (
                  <span className="text-sm font-inter-medium ">
                    {priceFormatter(cart?.data?.attributes?.subtotal)} • Proceed to checkout
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      )}

      {removeItemId && (
        <ConfirmationModal
          visible={removeItemId}
          setModal={setRemoveItemAlert}
          title={'Remove item'}
          body={'Are you sure you want to remove this item?'}
          rightButtonText={'Delete'}
          onClickRightButton={onClickRemove}
        />
      )}

      {clearCartAlert && (
        <ConfirmationModal
          visible={clearCartAlert}
          setModal={setClearCartAlert}
          title={'Clear cart'}
          body={'Are you sure you want to clear your cart of all its contents?'}
          rightButtonText={'Clear Cart'}
          onClickRightButton={onClearCart}
        />
      )}

      {attachLabelAlert && (
        <ConfirmationModal
          visible={attachLabelAlert}
          setModal={setAttachLabelAlert}
          title={'Item assigned to an individual name'}
          body={`You need to remove the attached name to increase the quantity of this item. If you want to add items with no names attached, please select item again and choose the quantity.`}
          rightButtonText={'Okay'}
          onClickRightButton={() => {
            setAttachLabelAlert(null);
          }}
        />
      )}

      {editItemModal && (
        <AddItemModal
          edit
          editItem={editItem}
          visible={editItemModal}
          setModal={setEditItemModal}
          updateItem={updateItem}
        />
      )}
    </div>
  );
}

export default observer(Cart);
