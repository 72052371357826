import { EmptyCart, TimerClock } from 'assets/img';
import ConfirmationModal from 'components/ConfirmationModal';
import EmbedHTML from 'components/Shared/EmbedHTML';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { StoreContext } from 'pages/_app';
import { useContext, useState } from 'react';
import { groupBy, isPresent } from 'utils/helpers/array';
import { priceFormatter } from 'utils/helpers/validation';
import GroupOrderCartItem from '../GroupOrderCartItem';
import AddGroupItemModal from '../AddGroupItemModal';
import classNames from 'classnames';
import PlaceOrderButton from './PlaceOrderButton';

function GroupOrderCart({ restaurantDetail, groupOrder }) {
  const [removeItemId, setRemoveItemAlert] = useState(null);
  const [clearCartAlert, setClearCartAlert] = useState(null);
  const [editItemModal, setEditItemModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [attachLabelAlert, setAttachLabelAlert] = useState(null);
  const store = useContext(StoreContext);
  const groupOrderStore = toJS(store?.groupOrderStore);
  const router = useRouter();
  let { cart, orderDetails, attendeeId } = groupOrderStore;
  const isAdmin = router?.query?.admin;

  const onClickRemove = () => {
    if (removeItemId) {
      store?.userStore?.setLoader(true);
      store?.groupOrderStore
        ?.removeGroupOrderCartItem({
          id: removeItemId,
          admin: isAdmin ? true : false,
        })
        .then(() => {
          store?.userStore?.setLoader(false);
          store?.groupOrderStore?.getAllGroupOrderCartItems({
            slug: orderDetails?.attributes?.slug,
            attendee_id: attendeeId,
            admin: isAdmin ? true : false,
          });
          setRemoveItemAlert(null);
        });
    }
  };

  const onClearCart = () => {
    store?.userStore?.setLoader(true);
    store?.groupOrderStore
      ?.removeAllGroupOrderCartItems({
        remove_all_cart_items: true,
        slug: orderDetails?.attributes?.slug,
        attendee_id: attendeeId,
        admin: isAdmin ? true : false,
      })
      .then(() => {
        store?.userStore?.setLoader(false);
        setClearCartAlert(null);
      });
  };

  const editCartItem = cartItem => {
    setEditItem(cartItem);
    setEditItemModal(true);
  };

  const updateItem = (id, payload) => {
    store.userStore.setLoader(true);
    store?.groupOrderStore?.updateGroupOrderItem(id, payload).then(() => {
      store.userStore.setLoader(false);
      setEditItemModal(false);

      store?.groupOrderStore?.getAllGroupOrderCartItems({
        slug: orderDetails?.attributes?.slug,
        attendee_id: attendeeId,
        admin: isAdmin ? true : false,
      });
    });
  };

  const getLimit = () => {
    const limit = parseFloat(groupOrder?.attributes?.per_person_budget);
    const subtotal = parseFloat(cart?.data?.subtotal);

    if (!isPresent(cart?.items)) return `${priceFormatter(limit)} limit`;
    else if (limit >= subtotal)
      return `${priceFormatter(limit - subtotal)} left from ${priceFormatter(limit)} limit`;
    else return `${priceFormatter(subtotal - limit)} above your ${priceFormatter(limit)} limit`;
  };

  const isAbovelimit = () => {
    return parseFloat(cart?.data?.subtotal) > parseFloat(groupOrder?.attributes?.per_person_budget);
  };

  return (
    <>
      <div className="flex flex-col flex-grow-1 min-h-[calc(100vh-50px)] md:min-h-[calc(100vh)] max-h-screen md:max-h-[calc(100vh)] static md:sticky overflow-auto top-[0.5rem]">
        {groupOrder && (
          <>
            {!isAdmin && (
              <div className="flex flex-col mb-3 md:mt-4 bg-background items-center justify-center p-3 rounded-xl">
                <span
                  className={classNames({
                    'text-md font-inter-semibold text-hh-accent-light': true,
                    'text-attention': isAbovelimit(),
                  })}>
                  {' '}
                  {getLimit()}
                </span>

                <span className="text-md mt-[10px] font-inter-regular">
                  Deadline to place your order:
                </span>

                <div className="flex mt-[10px]">
                  <Image src={TimerClock} className="icon" />

                  <span className="text-md ml-2">
                    {groupOrder?.attributes?.formatted_attendee_deadline}
                  </span>
                </div>
              </div>
            )}

            {isAbovelimit() && !isAdmin && (
              <span className="font-inter-medium text-md text-attention text-center mt-4">
                Please remove items to stay below your limit
              </span>
            )}

            <div className="hidden md:flex">
              <PlaceOrderButton isAdmin={isAdmin} />
            </div>
          </>
        )}

        <div className="flex flex-col md:border border-background md:mt-4 rounded-3xl p-6">
          <span className="text-divider text-md">Your cart</span>

          {cart?.items?.length == 0 ? (
            <>
              <div className="flex items-center w-3/4">
                <span className="font-inter-medium text-md2 mr-2 text-black-light">
                  {restaurantDetail?.attributes?.display_name}
                </span>
              </div>

              <div className="flex flex-col justify-center items-center my-10">
                <div className="mt-5">
                  <Image src={EmptyCart} width={200} height={150} alt="empty-cart" />
                </div>

                <span className="font-inter-medium text-md2 text-primary-black mt-3">
                  Add your first item to cart
                </span>
              </div>
            </>
          ) : (
            <div className="flex flex-col flex-1 justify-between">
              <div>
                <div className="flex justify-between items-center mt-1">
                  <div className="flex items-center w-3/4">
                    <span className="font-inter-medium text-md2 mr-2 text-black-light">
                      {restaurantDetail?.attributes?.display_name}
                    </span>
                  </div>

                  <button
                    onClick={() => setClearCartAlert({ clearCart: true })}
                    className="text-sm btn-style text-hh-accent-dark">
                    Clear Cart
                  </button>
                </div>

                <div className="flex flex-col mt-4 mb-10 md:mb-0">
                  {Object.values(
                    groupBy(cart?.items, item => item?.attributes?.menu_category_id)
                  )?.map(entry => (
                    <>
                      <div className="flex justify-between items-center bg-background p-3 rounded-md">
                        <span className="text-md text-primary-black">
                          <EmbedHTML
                            text={
                              entry[0]?.attributes?.category_display_name_with_html ||
                              entry[0]?.attributes?.category
                            }
                            className="text-primary-black"
                            append={` (${entry?.length})`}
                          />
                        </span>
                      </div>

                      {entry?.map(cartItem => (
                        <GroupOrderCartItem
                          moreThanOneItemInCategory={entry.length > 1}
                          cartItem={cartItem}
                          key={cartItem.id}
                          setEditItem={editCartItem}
                          setRemoveItemAlert={setRemoveItemAlert}
                        />
                      ))}
                    </>
                  ))}
                </div>
              </div>
            </div>
          )}

          {removeItemId && (
            <ConfirmationModal
              visible={removeItemId}
              setModal={setRemoveItemAlert}
              title={'Remove item'}
              body={'Are you sure you want to remove this item?'}
              rightButtonText={'Delete'}
              onClickRightButton={onClickRemove}
            />
          )}

          {clearCartAlert && (
            <ConfirmationModal
              visible={clearCartAlert}
              setModal={setClearCartAlert}
              title={'Clear cart'}
              body={'Are you sure you want to clear your cart of all its contents?'}
              rightButtonText={'Clear Cart'}
              onClickRightButton={onClearCart}
            />
          )}

          {attachLabelAlert && (
            <ConfirmationModal
              visible={attachLabelAlert}
              setModal={setAttachLabelAlert}
              title={'Item assigned to an individual name'}
              body={`You need to remove the attached name to increase the quantity of this item. If you want to add items with no names attached, please select item again and choose the quantity.`}
              rightButtonText={'Okay'}
              onClickRightButton={() => {
                setAttachLabelAlert(null);
              }}
            />
          )}

          {editItemModal && (
            <AddGroupItemModal
              edit
              editItem={editItem}
              visible={editItemModal}
              setModal={setEditItemModal}
              updateItem={updateItem}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default observer(GroupOrderCart);
