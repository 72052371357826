export const priceFormatter = number => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(number);
};

export const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const preventMinus = e => {
  if (e.code === 'Minus') {
    e.preventDefault();
  }
};

export const preventPasteNegative = e => {
  const clipboardData = e.clipboardData || window.clipboardData;
  const pastedData = parseFloat(clipboardData.getData('text'));

  if (pastedData < 0) {
    e.preventDefault();
  }
};

export const validatePassword = password => {
  return /^(?=.*\d)(?=.*[a-zA-Z]).{8,100}$/.test(password);
};

export const handleKeyDown = event => {
  // Allow only arrow up and arrow down key events
  if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === '-') {
    event.preventDefault();

    // Get the current value of the input
    const currentValue = parseFloat(event.target.value) || 0;

    // Calculate the new value based on the arrow key pressed
    let newValue;
    if (event.key === 'ArrowUp') {
      newValue = currentValue + 1;
    } else if (event.key === 'ArrowDown') {
      newValue = currentValue - 1;
    }

    // Prevent negative values
    if (newValue < 0) {
      newValue = 0;
    }

    // Update the input value
    event.target.value = newValue;
  }
};

export const validateValueGreaterThanZero = (rule, value) => {
  if (!value) {
    return Promise.reject('This field needs to be filled');
  }

  const parsedValue = parseInt(value, 10); // Convert the value to an integer

  if (isNaN(parsedValue) || parsedValue <= 0) {
    return Promise.reject('Value must be a positive number');
  }

  return Promise.resolve();
};

export const validateSpaces = (rule, value) => {
  if (!value) {
    return Promise.reject('This field needs to be filled');
  }

  if (!value.trim()) {
    return Promise.reject('Value must be some meaningful');
  }

  return Promise.resolve();
};

export const avoidMinus = event => {
  // Check if the pressed key is "-"
  if (event.key === '-') {
    event.preventDefault();
  }
};
